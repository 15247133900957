<template>
  <DashboardAxerta v-if="company.nome == 'Axerta'" />
  <div v-else class="grid-center">
    <div>
      <h1>Benvenuto!</h1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import DashboardAxerta from "@/views/axerta/DashboardAxerta.vue"
import AxiosService from "@/axiosServices/AxiosService.js"
export default {
  components: { DashboardAxerta },
  computed: mapGetters(["company"]),
  mounted() {
    if (this.company.nome == "Axerta") {
      this.createWidget()
    }
  },
  data() {
    return {
      userId: "Francese_BO_Vincenzo",
      region: "eu",
      environment: "sandbox",
      appId:
        "wAppId_afdc43bb4e41128b379a6bb5f161b82ccb03c81cda43b236a9f36ad2acae",
      accessToken: "",
    }
  },
  methods: {
    async createWidget() {
      window.Client = window.BandyerSDK.configure({
        appId: this.appId,
        region: this.region,
        environment: this.environment,
        tools: {
          chat: true,
        },
      })
      try {
        Client.on("client:access_token:is_about_to_expire", async (data) => {
          const accessToken = await this.retrieveAccessToken(
            this.region,
            this.environment,
            this.userId
          )
          console.log(
            "🚀 ~ file: testtry2.vue:34 ~ Client.on ~ accessToken:",
            accessToken
          )
          const { expiresAt } = await Client.updateAccessToken(accessToken)
        })
        Client.on("client:access_token:expired", async () => {
          await Client.disconnect()
          const accessToken = await this.retrieveAccessToken(
            this.region,
            this.environment,
            this.userId
          )
          console.log(
            "🚀 ~ file: testtry2.vue:47 ~ Client.on ~ accessToken:",
            accessToken
          )
          await Client.connect(this.userId, accessToken)
        })

        const service = new AxiosService(`VideoChat/GetToken/${this.userId}`)

        await this.retrieveAccessToken(
          this.region,
          this.environment,
          this.userId
        )
        if (this.accessToken) {
          await Client.connect(this.userId, this.accessToken)
        } else {
          service.read().then((res) => {
            this.accessToken = res
            Client.connect(this.userId, this.accessToken)
          })
        }
      } catch (e) {
        console.error("Fail to retrieve the access token or to connect", e)
      }
    },
    async retrieveAccessToken(region, environment, userId) {
      // Implement your logic to retrieve the access token from the server
      // This function should return a promise that resolves to the access token

      const service = new AxiosService(`VideoChat/GetToken/${userId}`)

      service.read().then((res) => {
        this.accessToken = res
      })
    },
  },
}
</script>

<style scoped>
.grid-center {
  display: grid;
  height: 50vh;
  place-content: center;
}
</style>
